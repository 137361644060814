import logo from './logo.svg';
import './App.scss';
import axios from "axios";
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
const endPoint = "https://paynowolympuspredictor.online/_101casino/admin/";

function EditContent({data, setEditContent, adminKey}) {
  const [details, setDetails] = useState([]);
  useEffect(() => {
    axios.get(`${endPoint}${adminKey}/products/${data.id}/details`).then(response => {
      if (response.data.success) {
        setDetails(response.data.response);
        return;
      }
      toast.error(response.data.message);
    })
  }, []);
  return (
    <div className='content-edit'>
      <a id='click' className="goback" onClick={() => setEditContent(null)}>{"< Go Back"}</a>
      <table>
        <tr>
          <th className='column index'>#</th>
          <th className='column text'>Text</th>
          <th className='column delete'>Delete</th>
        </tr>
        {
          details.map((detail, index) => {
            return (<tr>
              <td className='value index'>{index}</td>
              <td className='value text'>{detail.text}</td>
              <td className='value delete'><a id='click' onClick={() => {
                axios.post(`${endPoint}${adminKey}/details/delete/${detail.id}`).then(response => {
                  if (response.data.success) {
                    toast.success(response.data.message);
                    setDetails(details => [...details.filter(v => v.id !== detail.id)])
                    // setEditContent({...data, details: data.details.filter(detailR => detailR.id !== detail.id)});
                    return;
                  }
                  toast.error(response.data.message);
                });
              }}>Delete</a></td>
            </tr>)
          })
        }
      </table>
      <form onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        axios.post(`${endPoint}${adminKey}/details/create`, {
          product: data.id,
          text: formData.get("text")
        }).then(response => {
          if (response.data.response) {
            setDetails(details => [...details, response.data.response]);
            // setEditContent({...data, details: [...data.details, response.data.response]});
            toast.success(response.data.message);
            return;
          }
          toast.error(response.data.message);
        });
      }}>
        <input type='text' name='text' placeholder='Detail Text'/>
        <input type='submit' id="click" value={"Create"}/>
      </form>
    </div>
  )
}

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [editContent, setEditContent] = useState(null);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (!token) return;
    axios.get(`${endPoint}${token}/products`).then(response => {
      if (!response.data.success) {
        localStorage.removeItem("token");
        setToken(null);
        return toast.error(response.data.message);
      }
      setProducts(response.data.response);
    });
  }, []);
  return (
    <div className='App'>
      <ToastContainer />
      {
        !token ? (
          <div className='input'>
            <h3>Login</h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              const data = new FormData(e.target);
              const key = data.get('key');
              axios.get(`${endPoint}${key}`).then(response => {
                if (response.data.success) {
                  toast.success(response.data.message);
                  localStorage.setItem("token", key);
                  setToken(key);
                  return;
                }
                toast.error(response.data.message);
              }).catch(() => {
                toast.error("Invalid key!")
              })
            }}>
              <input type='text' name='key' placeholder='Enter your authorization key'/>
              <input type='submit' id="click" value={"Send"}/>
            </form>
          </div>
        ) : editContent ? <EditContent data={editContent} adminKey={token} setEditContent={setEditContent} /> : (
          <div className="content">
            <table>
              <tr>
                <th className='column id'>#</th>
                <th className='column title'>Title</th>
                <th className='column price'>Price</th>
                <th className='column edit'>Edit</th>
              </tr>
              {
                products.sort((a, b) => a.id - b.id).map((product, index) => {
                  return (
                    <tr>
                      <td className='value id'>{product.id}</td>
                      <td className='value title'>{product.title}</td>
                      <td className='value price'>${product.price}</td>
                      <td className='value edit'><a id='click' onClick={() => {
                        setEditContent(product)
                      }}>
                        Edit
                      </a></td>
                    </tr>
                  )
                })
              }
            </table>
            <form onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              axios.post(`${endPoint}${token}/products/create`, {
                title: formData.get("title"),
                price: formData.get("price")
              }).then(response => {
                if (response.data.response) {
                  setProducts(products => [...products, response.data.response]);
                  toast.success(response.data.message);
                  return;
                }
                toast.error(response.data.message);
              })
            }}>
              <a>Title</a>
              <input type='text' name='title' defaultValue={"Product title"}/>
              <a>Price</a>
              <input type='number' name="price" defaultValue={100}/>
              <input type='submit' id='click' value={"Create"}/>
            </form>
          </div>
        )
      }
    </div>

  );
}

export default App;
